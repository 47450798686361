import { FieldProps, NonEmptyArray } from 'formoid';
import { ComponentProps } from 'react';
import { CountryFlag, Errors, ItemRendererProps, Option, SelectFactory } from '~/common/components';
import { Any, cx } from '~/common/utils';
import { useCountriesData } from '~/hooks';
import { FormElementLabel, FormElementLabelProps } from '../Input';
import css from './PhoneInput.module.scss';

// TODO evaluate whether the interface of this component is ok, because we'll use it
// only inside forms, and those have split dial code and phone number
// however in design it looks like single component
// maybe it's better to make it a single fieldprops and transform form values before submit?
// maybe it's even better to make nested fields on be, e.g.
// { code: id, phone: string } instead of separate fields, so even BE error passthrough will work OOB with single fieldprops?
//
// since breaking changes on BE are undesirable, we could make a single
// fieldProps component first, that spits out { code: id, phone: string }
// in onChange, but then wrap it in a compat layer that uses two fieldProps

const joinErrors = (one: NonEmptyArray<string> | null, two: NonEmptyArray<string> | null) => {
  const result = [];

  if (one) {
    result.push(...one);
  }

  if (two) {
    result.push(...two);
  }

  return result.length ? (result as NonEmptyArray<string>) : null;
};

type UseOptions<T extends Option<Any>> = Pick<
  ComponentProps<typeof SelectFactory<T>>,
  'options' | 'filterOptions' | 'itemRenderer'
> & {
  codeGetter: (value: T['value']) => string | null;
};

type PhoneSelectProps<T extends Option<Any>> = FormElementLabelProps & {
  className?: string;
  disabled?: boolean;
  codeProps: FieldProps<number | null>;
  phoneProps: FieldProps<string> | FieldProps<string | null>;
  useOptions: () => UseOptions<T>;
};

export const PhoneInputField = <T extends Option<Any>>({
  title,
  hint,
  required,
  hintClickable,
  className,
  codeProps,
  phoneProps: rawPhoneProps,
  disabled: globalDisabled,
  useOptions,
  ...props
}: PhoneSelectProps<T>) => {
  const { codeGetter, ...restOptions } = useOptions();
  const countryCode = codeProps.value != null ? codeGetter(codeProps.value) : undefined;

  // TODO this looks ugly enough to rethink component's interface :)
  const errors = joinErrors(codeProps.errors, rawPhoneProps.errors);
  const error = !!errors;
  const disabled = globalDisabled || codeProps.disabled || rawPhoneProps.disabled;

  const { touched: _phoneTouched, errors: _unusedPhoneErrors, ...phoneProps } = rawPhoneProps;

  return (
    <SelectFactory
      {...props}
      {...restOptions}
      onChange={codeProps.onChange}
      value={codeProps.value}
      inputField={({
        ref,
        children,
        onClick,
        onMouseDown,
        onMouseLeave,
        onPointerDown,
        ...inputProps
      }) => (
        <div
          className={cx(
            css.wrapper,
            {
              [css.error]: error,
              [css.disabled]: disabled,
            },
            className,
          )}
        >
          <FormElementLabel
            title={title}
            hint={hint}
            required={required}
            hintClickable={hintClickable}
            disabled={disabled}
          />
          <div className={css.inputsWrapper}>
            <div
              ref={ref}
              className={css.codeInputWrapper}
              {...(disabled ? undefined : { onClick, onMouseDown, onMouseLeave, onPointerDown })}
            >
              {countryCode != null && (
                <CountryFlag countryCode={countryCode} className="cursor-pointer" />
              )}
              <input
                {...inputProps}
                autoComplete="tel-country-code"
                disabled={disabled}
                className={css.codeInput}
              />
              {children && (
                <div onMouseDown={(e) => e.preventDefault()} className={css.children}>
                  {children}
                </div>
              )}
            </div>
            <input
              {...phoneProps}
              value={phoneProps.value ?? ''}
              type="tel"
              autoComplete="tel-national"
              disabled={disabled}
              className={css.numberInput}
              onChange={(e) => phoneProps.onChange(e.target.value)}
              placeholder="55 555 55 55"
            />
          </div>
          {errors && <Errors errors={errors} />}
        </div>
      )}
    />
  );
};

export const useByDialCodeOptions = () => {
  const { dialCodes } = useCountriesData();

  return {
    options: dialCodes,
    filterOptions: filterByCodeCountryCodes,
    // revert once we move to flags countryIds everywhere
    itemRenderer: undefined,
    codeGetter: () => null,
  };
};

type CountriesData = ReturnType<typeof useCountriesData>;

export const useByIdOptions = () => {
  const { dialCodesById } = useCountriesData();

  return {
    options: dialCodesById,
    filterOptions: filterByIdCountryCodes,
    // revert once we move to flags countryIds everywhere
    itemRenderer: undefined,
    codeGetter: () => null,
  };
};

export type ByCodeCountryOption = CountriesData['dialCodes'][number];

export const filterByCodeCountryCodes = (search: string) => (option: ByCodeCountryOption) =>
  option.lookup.includes(search);

export const byCodeCountryRenderer = ({
  option,
  inputValue,
  ...props
}: ItemRendererProps<ByCodeCountryOption>) => (
  <li {...props} className={cx(props.className, 'flex gap-1 items-center w-full')}>
    {option.code && <CountryFlag countryCode={option.code} />}
    <span>+{option.value}</span>
  </li>
);

export type ByIdCountryOption = CountriesData['dialCodesById'][number];

export const filterByIdCountryCodes = (search: string) => (option: ByIdCountryOption) =>
  option.lookup.includes(search);

export const byIdCountryRenderer = ({
  option,
  inputValue,
  ...props
}: ItemRendererProps<ByIdCountryOption>) => (
  <li {...props} className={cx(props.className, 'flex gap-1 items-center w-full')}>
    <CountryFlag countryCode={option.code} />
    <span>{`+${option.dialCode}`}</span>
  </li>
);
