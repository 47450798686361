import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { FieldProps, NonEmptyArray } from 'formoid';
import { useState } from 'react';
import { useEvent } from '~/common/hooks';
import { getPercentage } from '~/common/utils';
import { UploadedFile, UploadedFileId } from '~/root/domain';
import { getFriendlyUploadError, tusUpload } from '~/utils/tusUpload';

type Params = {
  tag: string;
  props: FieldProps<UploadedFileId | null>;
  onError: (error: NonEmptyArray<string> | null) => void;
  existingFilename?: string | null;
  existingFile?: UploadedFile | null;
  preprocess?: (file: File) => Promise<File>;
  validateFile?: (file: File) => Promise<NonEmptyArray<string> | null>;
};

export const useFileUploadState = ({
  tag,
  props,
  onError,
  validateFile: validate = async () => null,
  preprocess,
  existingFile = null,
}: Params) => {
  const [file, setFile] = useState<UploadedFile | File | null>(existingFile);
  const [progress, setProgress] = useState(0);

  const resetErrors = () => onError(null);

  const onBlur = useEvent(props.onBlur);

  const preprocessFile = async (file: File): Promise<File> => {
    if (!preprocess) {
      return file;
    }

    return await preprocess(file).catch((e) => {
      captureException(e);
      return file;
    });
  };

  const validateFile = async (file: File) => {
    const errors = await validate(file);
    if (errors) {
      onError(errors);
    }
    return !errors;
  };

  const mutation = useMutation({
    mutationFn: async (file: File) => {
      const isFileValid = await validateFile(file);
      if (!isFileValid) {
        return;
      }

      const preprocessedFile = await preprocessFile(file);

      setFile(preprocessedFile);

      return tusUpload({
        file: preprocessedFile,
        tag,
        onProgress: (bytesSent, bytesTotal) => setProgress(getPercentage(bytesSent, bytesTotal)),
      }).catch((error) => {
        // the reason we're catching here is because otherwise we can't prevent
        // react-query from screaming about handled errors, and we can't make
        // it vocal about not handled errors
        const { message, shouldThrow } = getFriendlyUploadError(error);
        onError(message);
        if (shouldThrow) {
          throw error;
        }
        return undefined;
      });
    },
    onMutate: resetErrors,
    onSuccess: (next) => {
      if (next) {
        setFile(next);
        props.onChange(next.id);
        setTimeout(onBlur, 50);
      }
    },
  });

  return {
    ...props,
    value: file?.name,
    file: file as UploadedFile,
    onChange: (file: File | null) => {
      setProgress(0);

      if (file) {
        mutation.mutate(file);
      } else {
        setFile(null);
        props.onChange(null);
        resetErrors();
      }
    },
    loading: mutation.isLoading,
    progress,
  };
};
