import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { ReactNode } from 'react';
import { Button } from '~/common/components';
import { cx } from '~/common/utils';

type ModalHeaderProps = {
  title?: string;
  subtitle?: ReactNode;
  className?: string;
  onClose?: () => void;
};

export const ModalCloseButton = ({
  onClose,
  className,
}: Pick<ModalHeaderProps, 'onClose' | 'className'>) => (
  <Button.Icon
    icon={faTimes}
    className={cx(
      '!absolute top-0 right-0 !h-7 !w-7 def:!text-text-200 cursor-pointer focus:outline-none z-10',
      className,
    )}
    onClick={onClose}
  />
);

export const ModalHeader = ({ title, subtitle, className, onClose }: ModalHeaderProps) => (
  <div className={cx(className, 'relative min-w-0 def:mb-3')}>
    {title && (
      <div className="inline-flex flex-col pl-3 pt-4 text-text-500 md:px-4">
        <span className="font-brand-h5">{title}</span>
        {typeof subtitle === 'string' ? (
          <span className="mt-1 inline-block text-text-400 font-brand-b3">{subtitle}</span>
        ) : (
          subtitle
        )}
      </div>
    )}
    {onClose && <ModalCloseButton onClose={onClose} />}
  </div>
);
