import {
  FloatingFocusManager,
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useRole,
  useTransitionStatus,
} from '@floating-ui/react';
import { ReactNode } from 'react';
import { MAX_TAB_INDEX } from '~/common/hooks';
import { cx, noop } from '~/common/utils';
import { FloatingDiv, outsidePress, withFloatingTree } from '../Floating';
import styles from './ModalCore.module.scss';

type Props = {
  opened: boolean;
  children: ReactNode;
  className?: string;
  onClose?: () => void;
  noDismiss?: boolean;
};

export const ModalCore = withFloatingTree(
  ({ opened, children, onClose = noop, className, noDismiss }: Props) => {
    const nodeId = useFloatingNodeId();

    const { refs, context } = useFloating({
      nodeId,
      open: opened,
      onOpenChange: (state) => {
        if (!state) {
          onClose();
        }
      },
    });

    const { getFloatingProps } = useInteractions([
      useClick(context),
      useRole(context),
      useDismiss(context, { outsidePress, enabled: !noDismiss }),
    ]);

    const { isMounted, status } = useTransitionStatus(context);

    if (!isMounted) {
      return null;
    }

    return (
      <FloatingNode id={nodeId}>
        <FloatingPortal>
          <FloatingOverlay lockScroll className={styles.backdrop} data-status={status}>
            <FloatingFocusManager context={context} initialFocus={MAX_TAB_INDEX}>
              <FloatingDiv
                {...getFloatingProps({
                  ref: refs.setFloating,
                  className: cx(styles.modal, className, 'focus-visible:outline-none'),
                })}
                data-status={status}
                onTransitionEnd={() => status === 'close' && onClose()}
              >
                {children}
              </FloatingDiv>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      </FloatingNode>
    );
  },
);
