import dayjs from 'dayjs';
import { z } from 'zod';

export const holidaysSchema = z
  .object({
    days: z.record(
      z.array(
        z.object({
          id: z.number(),
          title: z.string(),
          message: z.string(), // May be unused
          no_availability: z.boolean(),
        }),
      ),
    ),
  })
  .transform(({ days }) => {
    const holidaysList = Object.entries(days).flatMap(([date, holidays]) => {
      return holidays.map(({ id, title, no_availability }) => ({
        id: id + '_' + date,
        rangeId: id,
        title,
        date: new Date(date),
        day: new Date(date),
        no_availability,
      }));
    });

    const upcomingHolidaysList = holidaysList.filter(
      ({ date }) => dayjs().isBefore(date, 'day') || dayjs().isSame(date, 'day'),
    );

    return {
      holidays: days,
      holidaysList,
      upcomingHolidaysList,
    };
  });

export type HolidayListItem = z.infer<typeof holidaysSchema>['holidaysList'][number];
