import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { ReactNode } from 'react';
import { Button, IconBox, ModalContentProps, ModalHeader } from '~/common/components';
import { ButtonProps } from '~/common/components/Interactives/types';
import { tw, cx } from '~/common/utils';

const buttonsClassName = tw`
  flex items-center justify-end gap-3
  px-3 py-2 w-full
  border-t border-solid border-other-200
`;

export const ButtonGroup = ({ children }: { children: ReactNode }) => (
  <div className={buttonsClassName}>{children}</div>
);

type Props = {
  disabled?: boolean;
  disabledHint?: string;
  loading?: boolean;
  children?: ReactNode;
  className?: string;
  buttonsSize?: ButtonProps['size'];
  negativeAction?: boolean;
  submitText?: string;
  cancelText?: string;
  onClose: () => void;
  onSubmit: () => void;
};

export const ModalShell = ({
  loading = false,
  disabled,
  disabledHint,
  className,
  negativeAction = false,
  submitText = 'Save',
  cancelText = 'Cancel',
  buttonsSize = 'm',
  onClose,
  onSubmit,
  children,
}: Props) => (
  <>
    <div className={cx('def:px-3 def:md:px-4 def:w-[616px]', className)}>{children}</div>
    <ButtonGroup>
      <Button color="text" size={buttonsSize} onClick={onClose} disabled={loading}>
        {cancelText}
      </Button>
      <Button
        data-tt={cx(disabled && disabledHint)}
        className={cx(disabled && 'cursor-not-allowed', 'w-full md:w-auto')}
        color={negativeAction ? 'error' : 'primary'}
        size={buttonsSize}
        onClick={onSubmit}
        loading={loading}
        disabled={disabled}
      >
        {submitText}
      </Button>
    </ButtonGroup>
  </>
);

type ConfirmModalShellProps = ModalContentProps & {
  onConfirm: () => void;
  title: string;
  description: string;
  submitText: string;
  loading?: boolean;
};

export const ConfirmModalShell = ({
  title,
  description,
  submitText,
  loading,
  onConfirm,
  onClose,
}: ConfirmModalShellProps) => (
  <>
    <ModalHeader title={title} onClose={onClose} className="mb-1" />
    <ModalShell
      submitText={submitText}
      onClose={onClose}
      onSubmit={onConfirm}
      loading={loading}
      className="py-0 w-[632px]"
    >
      <div className="flex gap-1 mb-1 text-error-300 font-brand-b3">
        <IconBox icon={faExclamationTriangle} className="h-3 w-3" />
        <p>{description}</p>
      </div>
    </ModalShell>
  </>
);
