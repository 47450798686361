import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useModalStore = create(
  persist(
    () => ({
      pro: false,
      onlineSubscriptionsPopup: false,
      couldSavePopup: false,
    }),
    { name: '_modal-store' },
  ),
);
