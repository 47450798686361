import { useCurrentUserData } from '~/root/Auth';
import { useNavigation } from '../../context';
import { NavigationLink } from '../../NavigationLink';
import { NavigationSection } from '../../NavigationSection';
import { SubNavigation } from '../../SubNavigation';
import { Credits, ProBanner } from '../banners';
import { config } from '../config';

// This menu is also used for subscriptions with 0 capacity points
export const RegularNavigation = () => {
  const { isFromHeader, isMenuCollapsed } = useNavigation();
  const { subscription } = useCurrentUserData();

  return (
    <>
      <NavigationSection>
        <NavigationLink {...config.links.myOrders} />
        <NavigationLink {...config.links.myTeam} />
        <NavigationLink {...config.links.billing} />
        <NavigationLink {...config.links.holidayCalendar} />
        <NavigationLink {...config.links.customFields} />
        <NavigationLink {...config.links.styleTemplates} />
        <SubNavigation {...config.subMenus.settings}>
          <NavigationLink {...config.links.userDetails} />
          <NavigationLink {...config.links.security} />
        </SubNavigation>
        <NavigationLink {...config.links.needHelp} />
      </NavigationSection>

      <NavigationSection>
        <Credits />
      </NavigationSection>
      {!isMenuCollapsed && !isFromHeader && !subscription && <ProBanner />}
      {isFromHeader && !subscription && (
        <NavigationSection>
          <ProBanner className="mx-2" />
        </NavigationSection>
      )}
    </>
  );
};
