import { forwardRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { create } from 'zustand';
import { Any, cx } from '~/common/utils';
import { IconBox } from '../IconContainers';
import { BaseLinkProps, LinkButtonProps, LinkIconProps, LinkProps, LinkTextProps } from './types';
import css from './styles.module.scss';

export const useStaleBundle = create<boolean>()(() => false);

const newTabProps = (newTab?: boolean) => ({
  target: newTab ? '_blank' : undefined,
  rel: newTab ? 'noreferrer' : undefined,
});

type Link = React.ForwardRefExoticComponent<LinkProps & React.RefAttributes<HTMLAnchorElement>> &
  LinkNamespace;

const LinkBase = forwardRef<HTMLAnchorElement, BaseLinkProps>(({ newTab, ...props }, ref) => {
  const stale = useStaleBundle();

  if (props.href === '' || props.to === '') {
    throw new Error(
      `Link must have either "to" or "href" prop as a non empty string. Check console for more info`,
    );
  }

  if (props.to && stale) {
    return <a ref={ref} {...props} {...newTabProps(newTab)} href={props.to} />;
  }

  if (props.href) {
    return <a ref={ref} {...props} {...newTabProps(newTab)} />;
  }

  return <RouterLink ref={ref} {...(props as RouterLinkProps)} {...newTabProps(newTab)} />;
});

const LinkStyledText = forwardRef<HTMLSpanElement, LinkProps>((props, ref) => {
  const { endIcon, icon, children, className, size = 's' } = props;
  return (
    <span ref={ref} className={cx(css.link, css[size], className)}>
      {icon && <IconBox className={css.iconContainer} icon={icon} size="m" />}
      {children && <span className="truncate">{children}</span>}
      {endIcon && <IconBox className={css.iconContainer} icon={endIcon} size="m" />}
    </span>
  );
});

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { endIcon, icon, children, className, size = 's', iconSize = 'm', ...restProps } = props;
  return (
    <LinkBase {...restProps} className={cx(css.link, css[size], className)} ref={ref}>
      {icon && <IconBox className={css.iconContainer} icon={icon} size={iconSize} />}
      {children && <span className="truncate">{children}</span>}
      {endIcon && <IconBox className={css.iconContainer} icon={endIcon} size={iconSize} />}
    </LinkBase>
  );
}) as Link;

const LinkIcon = forwardRef<HTMLAnchorElement, LinkIconProps>((props, ref) => {
  const { icon, size = 's', className, ...restProps } = props;
  return (
    <LinkBase {...restProps} ref={ref} className={cx(css.icon, css[size], className)}>
      <IconBox className={css.iconContainer} icon={icon} />
    </LinkBase>
  );
});

const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>((props, ref) => {
  const {
    children,
    color = 'primary',
    size = 's',
    className,
    icon,
    disabled,
    ...restProps
  } = props;
  return (
    <LinkBase
      {...restProps}
      ref={ref}
      className={cx(css.button, css[color], css[size], disabled && css.disabled, className)}
      onClick={disabled ? (e) => e.preventDefault() : restProps.onClick}
    >
      {icon && <IconBox className={css.iconContainer} icon={icon} />}
      {children && <span className="truncate">{children}</span>}
    </LinkBase>
  );
});

type LinkNamespace = {
  Base: typeof LinkBase;
  Icon: typeof LinkIcon;
  Button: typeof LinkButton;
  StyledText: React.ForwardRefExoticComponent<LinkTextProps & React.RefAttributes<HTMLSpanElement>>;
};

Link.Base = LinkBase;
Link.Icon = LinkIcon;
Link.Button = LinkButton;
Link.StyledText = LinkStyledText as Any;
