import { KeyboardEventHandler, ReactElement } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Any = any;

export type UnknownRecord = Record<string, unknown>;

export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

export type Props<P> = P & JSX.IntrinsicAttributes;

export type Component<P> = (props: Props<P>) => ReactElement<Any, Any> | null;

export type OnlyRequiredFields<T> = {
  [K in keyof T as undefined extends T[K] ? never : K]: T[K];
};

export type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>;

export type IntRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>;

export const tw = (stringsArray: TemplateStringsArray) =>
  stringsArray.join(' ').replace(/\s+/g, ' ').trim();

export const pluralize = (n: number) => (n === 1 ? '' : 's');

export const addLeadingZero = (number: number) => number.toString().padStart(2, '0');

export const handleEnterPress =
  <E extends HTMLElement>(handler: (...args: Array<Any>) => Any): KeyboardEventHandler<E> =>
  (event) => {
    if (event.code === 'Enter') {
      handler(event);
    }
  };

export const noop = () => {};
