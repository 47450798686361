import { AxiosError } from 'axios';
import { NonEmptyArray } from 'formoid';
import { Upload, UploadOptions } from 'tus-js-client';
import { Overwrite, UUID, getPercentage, megaBytesToBytes } from '~/common/utils';
import { SERVER_URL, CLIENT_VERSION } from '~/env';
import { Init } from '~/root/Auth';
import { UploadedFile } from '~/root/domain';
import { qk } from '~/root/query-keys';
import { queryClient } from '~/root/queryClient';

export const getTusErrorMessage = (error: unknown) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseText = (error as any)?.originalResponse?._xhr?.response;
  try {
    return JSON.parse(responseText)?.message ?? null;
  } catch {
    return null;
  }
};

export function isAbortError(error: unknown) {
  return (
    (error instanceof AxiosError && error.name === 'CanceledError') ||
    (error instanceof Error && error.name === 'AbortError')
  );
}

export function getFriendlyUploadError(error: unknown): {
  message: NonEmptyArray<string>;
  shouldThrow: boolean;
} {
  if (isAbortError(error)) {
    return { message: ['Aborted'], shouldThrow: false };
  }

  const uploadError = getTusErrorMessage(error);
  if (uploadError) {
    return { message: [uploadError], shouldThrow: false };
  }

  return {
    message: [
      error instanceof Error
        ? error.message
        : 'An error occured while uploading this file, please try to remove it and upload once more',
    ],
    shouldThrow: true,
  };
}

export const percentage =
  (callback: (progress: number) => void) => (bytesSent: number, bytesTotal: number) =>
    callback(getPercentage(bytesSent, bytesTotal));

type Options = Overwrite<
  UploadOptions,
  {
    file: File;
    tag: string;
    endpoint?: string;
    signal?: AbortSignal;
  }
>;

function createAbortError(): Error {
  return new DOMException('Aborted', 'AbortError');
}

export const tusUpload = ({
  file,
  tag,
  endpoint = '/uploads/',
  signal,
  chunkSize = megaBytesToBytes(50),
  metadata,
  ...options
}: Options): Promise<UploadedFile> => {
  return new Promise((resolve, reject) => {
    if (signal?.aborted) {
      reject(createAbortError());
    }

    // TODO I was just lazy to inject this token each time we use tusUpload
    // Drawback is that this util is now dependent on root, while also
    // importing queryClient directly instead getting it from the context
    //
    // At least we should make tusUpload instantiable to put some getHeaders
    // function when creating an app-wide instance
    //
    // Should work fine for now though
    const init = queryClient.getQueryData<Init>(qk.init);
    const auth = init?.user?.token ? { Authorization: `Bearer ${init.user.token}` } : undefined;

    const upload = new Upload(file, {
      ...options,
      headers: {
        ...auth,
        'X-Client-Version': CLIENT_VERSION,
      },
      endpoint: SERVER_URL + endpoint,
      chunkSize,
      metadata: {
        ...metadata,
        filename: file.name,
        filetype: file.type,
        tag,
      },
      removeFingerprintOnSuccess: true,
      onSuccess: () => {
        resolve({
          date: new Date(),
          id: (upload.url as string).split('+')[0].split('/').pop() as UUID,
          link: upload.url as string,
          name: file.name,
        });
      },
      onError: (error) => {
        const uploadError = getTusErrorMessage(error);
        if (uploadError) {
          // replace verbose error with user friendly one if it exists
          error.message = uploadError;
        }
        reject(error);
      },
    });

    upload.findPreviousUploads().then((previousUploads) => {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }

      // Start the upload
      upload.start();
    });

    signal?.addEventListener(
      'abort',
      () => {
        upload
          // TODO should we remove not fully uploaded file here by passing true to abort fn?
          .abort()
          .then(() => reject(createAbortError()))
          .catch(reject);
      },
      { once: true },
    );
  });
};
