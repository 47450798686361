import { NonEmptyArray } from 'formoid';
import { ChangeEventHandler, forwardRef, TextareaHTMLAttributes } from 'react';
import { cx, Overwrite, tw } from '../utils';
import { Errors, FormElementLabel, FormElementLabelProps } from '.';

type Props = Overwrite<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  FormElementLabelProps &
    Partial<{
      errors: null | NonEmptyArray<string>;
      inputClassName: string;
      onChange?: (value: string) => void;
    }>
>;

export const TextArea = forwardRef<HTMLLabelElement, Props>(
  ({ errors, className, title, required, hint, onChange, ...props }, ref) => {
    const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
      onChange?.(event.currentTarget.value);
    };

    return (
      <label ref={ref} className={cx('flex flex-col', className)}>
        <FormElementLabel title={title} required={required} hint={hint} />
        <textarea
          {...props}
          className={cx(
            tw`
              def:min-w-[200px] def:min-h-[100px] p-1 font-brand-b3 resize-y
              border border-solid def:border-other-400 rounded bg-other-50
              focus:border-primary-200 focus:bg-primary-100 focus:text-text-500
              disabled:border-other-300 disabled:cursor-not-allowed disabled:placeholder:text-text-200
            `,
            { 'bg-error-100 border-error-300 text-text-500': !!errors },
          )}
          onChange={handleChange}
        />
        {errors && <Errors errors={errors} />}
      </label>
    );
  },
);
