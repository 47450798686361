import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingFocusManager,
  FloatingNode,
  FloatingPortal,
  offset,
  Placement,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useTransitionStatus,
} from '@floating-ui/react';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MAX_TAB_INDEX } from '~/common/hooks';
import { Any, cx } from '~/common/utils';
import fade from '~/styles/fade.module.scss';
import { FloatingDiv, outsidePress, withFloatingTree } from './Floating';
import { ModalContent } from './Modal';
import { styles } from './Tooltip';

export type PopoverTrigger = (
  ...props: [
    {
      ref: (node: HTMLElement | null) => void;
      onClick: (event: SyntheticEvent) => void;
      onKeyDown: () => void;
      onKeyUp: () => void;
      onPointerDown: (event: React.PointerEvent) => void;
    },
    boolean,
  ]
) => ReactNode;

type Props = {
  color?: 'white' | 'grey' | 'lightGrey' | 'danger';
  className?: string;
  placement?: Placement;
  fallbackPlacements?: Placement[];
  compensateOffset?: number;
  noDismiss?: boolean;
  noAncestorScroll?: boolean;
  onClose?: () => void;
  onClick?: () => void;
  externalState?: [
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>> | ((value: boolean) => void),
  ];
  openOnMount?: boolean;
  matchTriggerWidth?: boolean;
  showArrow?: boolean;
  content: ModalContent;
  padding?: number;
} & (
  | {
      trigger: PopoverTrigger;
      reference?: never;
    }
  | {
      trigger?: never;
      reference: Element | Range | null;
    }
);

export const Popover = withFloatingTree(
  ({
    trigger,
    color = 'white',
    className,
    placement = 'bottom',
    fallbackPlacements,
    compensateOffset = 0,
    noDismiss = false,
    noAncestorScroll = false,
    onClose,
    onClick,
    externalState,
    openOnMount = false,
    matchTriggerWidth = false,
    content,
    showArrow = false,
    padding = 8,
    reference,
  }: Props) => {
    const internalState = useState(openOnMount);
    const [open, setOpen] = externalState ?? internalState;
    const close = useCallback(() => setOpen(false), [setOpen]);

    const arrowRef = useRef<SVGSVGElement>(null);

    const nodeId = useFloatingNodeId();

    const { floatingStyles, refs, context } = useFloating({
      nodeId,
      placement,
      open,
      onOpenChange: setOpen,
      middleware: [
        offset(12 - compensateOffset),
        flip({ fallbackPlacements }),
        shift({ padding }),
        arrow({ element: arrowRef }),
        size({
          apply({ elements }) {
            if (matchTriggerWidth) {
              Object.assign(refs.floating.current?.style ?? {}, {
                width: `${elements.reference.getBoundingClientRect().width}px`,
                maxWidth: `${elements.reference.getBoundingClientRect().width}px`,
              });
            }
          },
        }),
      ],
      whileElementsMounted: autoUpdate,
    });

    // Virtual elements are not allowed to be passed to useFloating({elements}) :shrug:
    useEffect(() => {
      if (trigger) return;
      refs.setPositionReference(reference);
      setOpen(!!reference);
    }, [reference, refs, setOpen, trigger]);

    const { getReferenceProps, getFloatingProps } = useInteractions([
      useClick(context),
      useDismiss(context, {
        ancestorScroll: !noAncestorScroll,
        enabled: !noDismiss,
        outsidePress,
      }),
    ]);

    const { isMounted, status } = useTransitionStatus(context);

    return (
      <>
        {reference === undefined &&
          trigger(getReferenceProps({ ref: refs.setReference }) as Any, open)}

        {isMounted && (
          <FloatingNode id={nodeId}>
            <FloatingPortal>
              <FloatingFocusManager context={context} initialFocus={MAX_TAB_INDEX}>
                <FloatingDiv
                  {...getFloatingProps({
                    ref: refs.setFloating,
                    className: cx(
                      'pointer-events-auto focus-visible:outline-none',
                      styles.tooltip,
                      styles[color],
                      fade.floating,
                      className,
                    ),
                    style: floatingStyles,
                    onClick,
                  })}
                  data-status={status}
                  onTransitionEnd={() => status === 'close' && onClose?.()}
                >
                  {showArrow && (
                    <FloatingArrow
                      context={context}
                      ref={arrowRef}
                      width={12}
                      height={6}
                      className={cx(styles.arrow, styles[color])}
                      tipRadius={2}
                      strokeWidth={1}
                    />
                  )}
                  {content({ onClose: close })}
                </FloatingDiv>
              </FloatingFocusManager>
            </FloatingPortal>
          </FloatingNode>
        )}
      </>
    );
  },
);
