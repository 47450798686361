import { useNavigation } from '../../context';
import { NavigationLink } from '../../NavigationLink';
import { NavigationSection } from '../../NavigationSection';
import { SubNavigation } from '../../SubNavigation';
import { EnterpriseBanner } from '../banners';
import { config } from '../config';

export const SubscriptionNavigation = () => {
  const { isMenuCollapsed, isFromHeader } = useNavigation();

  return (
    <>
      <NavigationSection>
        <NavigationLink {...config.links.myOrders} />
        <NavigationLink {...config.links.myTeam} />
        <NavigationLink {...config.links.billing} />
        <NavigationLink {...config.links.holidayCalendar} />
        <NavigationLink {...config.links.customFields} />
        <NavigationLink {...config.links.styleTemplates} />
        <NavigationLink {...config.links.capacityOverview} />
        <SubNavigation {...config.subMenus.settings}>
          <NavigationLink {...config.links.userDetails} />
          <NavigationLink {...config.links.security} />
        </SubNavigation>
        <NavigationLink {...config.links.needHelp} />
      </NavigationSection>

      {!isMenuCollapsed && !isFromHeader && (
        <NavigationSection>
          <EnterpriseBanner />
        </NavigationSection>
      )}
    </>
  );
};
