import { HtmlHTMLAttributes, ReactNode } from 'react';
import { cx, Overwrite } from '~/common/utils';

type CardProps = Overwrite<
  HtmlHTMLAttributes<HTMLDivElement>,
  {
    children: ReactNode;
    title?: ReactNode;
    subtitle?: ReactNode;
    className?: string;
  }
>;

export const Card = ({ children, title, subtitle, className, ...props }: CardProps) => (
  <div
    className={cx(
      'px-3 md:px-4 py-3 rounded-md bg-white border border-solid border-other-300',
      className,
    )}
    {...props}
  >
    {(title || subtitle) && (
      <div className="flex flex-col gap-1 mb-3 whitespace-pre-wrap">
        <div className="font-brand-h5">{title}</div>
        {subtitle && <span className="font-brand-b3 text-text-400">{subtitle}</span>}
      </div>
    )}
    {children}
  </div>
);
