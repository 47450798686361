import { forwardRef } from 'react';
import { useLocation } from 'react-router-dom';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { IconBox, Link } from '~/common/components';
import { cx, tw } from '~/common/utils';
import { CustomerUser, useCurrentUser } from '../Auth';
import { useNavigation } from './context';
import { NavigationLink as NavigationLinkType } from './types';

export const menuItemClassnames = tw`
  flex items-center font-brand-b3 px-2 py-[12px] rounded
  hover:text-primary-400 hover:bg-other-100 transition-colors duration-300
  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-300 focus-visible:rounded-sm
`;

const useVisitedLinksStore = create(
  persist<Record<string, Date>>(() => ({}), { name: '_visited_links_store' }),
);

export const NavigationLink = forwardRef<HTMLAnchorElement, NavigationLinkType>(
  ({ icon, title, canShow, isNew, ...props }, ref) => {
    const { isMenuCollapsed } = useNavigation();
    const location = useLocation();
    const user = useCurrentUser();
    const visitedLinksStore = useVisitedLinksStore();
    const linkHref = props.href || props.to;

    if (canShow && !canShow(user as CustomerUser)) return null;

    const isActive = location.pathname.includes(props.to ?? props.href);

    return (
      <Link.Base
        ref={ref}
        {...props}
        onClick={(e) => {
          props.onClick?.(e);
          if (linkHref) {
            useVisitedLinksStore.setState({ [linkHref]: new Date() });
          }
        }}
        className={cx(menuItemClassnames, 'gap-1', { 'text-primary-300': isActive })}
        data-tt={cx(isMenuCollapsed && title)}
        data-tt-placement="right"
      >
        {icon && <IconBox size="s" icon={icon} />}
        {!isMenuCollapsed && <span className="truncate">{title}</span>}
        {!isMenuCollapsed && isNew && linkHref && !visitedLinksStore[linkHref] && (
          <div className="relative ml-auto">
            <span className="absolute w-1 h-1 animate-[ping_3s_cubic-bezier(0,_0,_0.2,_1)_infinite] bg-primary-300 rounded-full opacity-50" />
            <span className="w-1 h-1 rounded-full bg-primary-300 block" />
          </div>
        )}
      </Link.Base>
    );
  },
);
