/* eslint-disable no-console */
import posthogInstance, { PostHog } from 'posthog-js';
import { Any } from '~/common/utils';
import { ENV, POSTHOG_HOST, POSTHOG_KEY } from '~/env';
import { locationStore, userStore } from '../globalStores';

export const posthog = ENV.PRODUCTION
  ? posthogInstance
  : ({
      init: () => console.log('posthog init'),
      capture: (...args: Any[]) => console.log('posthog capture', ...args),
      identify: (...args: Any[]) => console.log('posthog identify', ...args),
      reset: () => {},
      onFeatureFlags: () => () => console.log('posthog onFeatureFlags'),
      getFeatureFlag: (name: string) => {
        console.log('posthog getFeatureFlags', name);
        return undefined;
      },
    } satisfies Partial<PostHog>);

export const posthogInit = () => {
  if (!POSTHOG_HOST || !POSTHOG_KEY) {
    return;
  }

  posthog.init(POSTHOG_KEY, {
    api_host: POSTHOG_HOST,
    autocapture: false,
    capture_pageview: false,
    debug: !ENV.PRODUCTION,
  });

  userStore.subscribe((user) => {
    if (!user) {
      return;
    }

    posthog.identify(user.id, {
      name: user.name,
      email: user.email,
      company: user.company,
      customer_account_id: user.customerAccountId,
    });
  });

  locationStore.subscribe(() => {
    posthog.capture('$pageview');
  });
};
