import { useCurrentUserData } from '~/root/Auth';
import { useNavigation } from '../../context';
import { NavigationLink } from '../../NavigationLink';
import { NavigationSection } from '../../NavigationSection';
import { SubNavigation } from '../../SubNavigation';
import { Credits, EnterpriseBanner, ProBanner } from '../banners';
import { config } from '../config';

export const ManagerNavigation = () => {
  const { isMenuCollapsed, isFromHeader } = useNavigation();
  const { hasDailyCapacity, purchasedPackages, manageCredits, seeCreditBalance, subscription } =
    useCurrentUserData();

  const showCreditsBanner =
    !hasDailyCapacity && (purchasedPackages ? manageCredits || seeCreditBalance : manageCredits);

  return (
    <>
      <NavigationSection>
        <NavigationLink {...config.links.myOrders} />
        <NavigationLink {...config.links.myTeam} />
        <NavigationLink {...config.links.billing} />
        <NavigationLink {...config.links.holidayCalendar} />
        <NavigationLink {...config.links.capacityOverview} />
        <SubNavigation {...config.subMenus.settings}>
          <NavigationLink {...config.links.userDetails} />
          <NavigationLink {...config.links.security} />
        </SubNavigation>
        <NavigationLink {...config.links.needHelp} />
      </NavigationSection>
      {showCreditsBanner && (
        <NavigationSection>
          <Credits />
        </NavigationSection>
      )}
      {isFromHeader && !subscription && (
        <NavigationSection>
          <ProBanner className="mx-2" />
        </NavigationSection>
      )}
      {!isMenuCollapsed &&
        !isFromHeader &&
        (subscription ? (
          <NavigationSection>
            <EnterpriseBanner />
          </NavigationSection>
        ) : (
          <ProBanner />
        ))}
    </>
  );
};
