import { NonEmptyArray } from 'formoid';
import { Ref, forwardRef } from 'react';
import { cx } from '../utils';
import { Errors, FormElementLabel, FormElementLabelProps } from './Input';
import { Button } from './Interactives';
import { Option } from './Select';

type VariantPickerProps<T> = FormElementLabelProps & {
  items: Option<T>[];
  value: string | null;
  onChange: (item: T) => void;
  errors?: null | NonEmptyArray<string>;
};

export const ChoiceField = forwardRef(
  <T extends string | boolean | number>(
    { items, title, required, hint, onChange, value, errors }: VariantPickerProps<T>,
    ref: Ref<HTMLDivElement>,
  ) => {
    return (
      <div ref={ref}>
        <FormElementLabel title={title} required={required} hint={hint} />
        <div className="flex flex-wrap gap-1">
          {items.map((item) => (
            <Choice
              key={typeof item.value === 'boolean' ? item.name : item.value}
              name={item.name}
              onClick={() => onChange(item.value)}
              isActive={item.value === value}
              isError={false} // design requirement not to show red state when error occured
            />
          ))}
        </div>
        {errors && <Errors errors={errors} className="!justify-start text-left" />}
      </div>
    );
  },
);

const Choice = ({
  name,
  isActive,
  onClick,
  isError,
}: {
  name: string;
  isActive: boolean;
  onClick: () => void;
  isError: boolean;
}) => {
  return (
    <Button.Base
      onClick={onClick}
      className={cx(
        'border font-brand-b3 rounded-[4px] px-[10px] py-[9px] bg-other-50',
        isActive ? 'border-secondary-300 text-text-500' : 'border-other-400 text-text-400',
        { '!border-error-300 !bg-error-100': isError },
      )}
    >
      {name}
    </Button.Base>
  );
};
