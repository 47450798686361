import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ReactNode } from 'react';
import { Button, IconBox } from '~/common/components';
import { cx } from '~/common/utils';

type Props<T extends string | number> = {
  value: T;
  setValue: (value: T) => void;
  options: {
    value: T;
    icon?: IconProp;
    name?: ReactNode;
  }[];
  className?: string;
};

export const SegmentedButton = <T extends string | number>({
  value,
  options,
  setValue,
  className,
}: Props<T>) => {
  return (
    <div className={cx('flex rounded-full border h-5 border-other-300 border-solid', className)}>
      {options.map((item) => {
        return (
          <div key={item.value} className="w-fit">
            <Button.Base
              onClick={() => setValue(item.value)}
              value={item.value}
              className={cx(
                'rounded-full h-full w-fit gap-1 flex items-center px-3 font-brand-l3 transition-all',
                value === item.value
                  ? 'bg-text-600 text-text-100 shadow-text-600 shadow-[0_0_0_1px]'
                  : 'text-text-600',
              )}
            >
              {item.icon && <IconBox icon={item.icon} />}
              {item.name}
            </Button.Base>
          </div>
        );
      })}
    </div>
  );
};
