import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { ReactNode } from 'react';
import { Button, IconBox, Popover } from '~/common/components';
import { cx } from '~/common/utils';
import { getItemClassName, Theme } from './utils';

interface SubsectionMenuProps {
  theme: Theme;
  content: ReactNode;
  label: string;
}

export const SubsectionMenu = ({ theme, content, label }: SubsectionMenuProps) => {
  const itemClassName = getItemClassName(theme);

  return (
    <Popover
      compensateOffset={-12}
      showArrow
      className="!z-[10001] hidden !w-fit !max-w-none !border-text-100 !p-3 lg:flex [&>svg]:!stroke-text-100"
      trigger={(props, open) => (
        <Button.Base {...props} className={itemClassName}>
          {label}
          <IconBox
            size="s"
            icon={faChevronLeft}
            className={cx(
              'transition-brand origin-center ml-[4px]',
              open ? 'rotate-90' : '-rotate-90',
            )}
          />
        </Button.Base>
      )}
      content={({ onClose }) => <div onClick={onClose}>{content}</div>}
    />
  );
};
