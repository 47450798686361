import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Link, Toast } from '~/common/components';
import { assertQueryData } from '~/common/kits/query';
import { routes } from '~/constants';
import { qk } from '~/root/query-keys';
import { useCurrentUser } from '../Auth';
import { axios } from '../axios';
import { posthog } from '../services';
import { HolidayListItem, holidaysSchema } from './domain';

export const useHolidaysCalendar = () => {
  const user = useCurrentUser();
  return useQuery({
    queryKey: qk.holidaysCalendar,
    queryFn: () => axios.get('v1/holidays').then(({ data }) => holidaysSchema.parse(data)),
    staleTime: Infinity,
    enabled: !!user,
    refetchOnWindowFocus: false,
  });
};

export const useHolidaysCalendarData = assertQueryData(useHolidaysCalendar);

type HolidaysNotificationStore = {
  dismissedNotifications: Record<string, string>;
  dismissNotification: (id: string) => void;
  isNotificationDismissed: (id: string) => boolean;
};

const DAY_ID_PREFIX = '-day';
const WEEK_ID_PREFIX = '-week';
const MONTH_ID_PREFIX = '-month';

export const useHolidaysNotificationStore = create<HolidaysNotificationStore>()(
  persist(
    (set, get) => ({
      dismissedNotifications: {},
      dismissNotification: (id) => {
        set((state) => ({
          dismissedNotifications: {
            ...state.dismissedNotifications,
            [id]: new Date().toISOString(),
          },
        }));
      },
      isNotificationDismissed: (id) => {
        return !!get().dismissedNotifications[id];
      },
    }),
    { name: '_holidays-notifications' },
  ),
);

const showNotification = (
  message: string,
  notifications: HolidayListItem[],
  timePrefix: string,
  dismissNotification: (id: string) => void,
) => {
  return Toast.info({
    title: 'Important',
    message: (
      <p className="font-brand-b2">
        {message}. For a list of upcoming holidays, see the{' '}
        <Link.StyledText className="!text-info-300" size="m">
          Calendar
        </Link.StyledText>
        .
      </p>
    ),
    id: notifications.map(({ id }) => id).join(','),
    link: routes.profile.holidays_calendar.index,
    onDismiss: () => {
      posthog?.capture('holidays_calendar', { event: 'dismiss_notification' });
      notifications.forEach(({ rangeId }) => dismissNotification(rangeId + timePrefix));
    },
    onClick: () => {
      posthog?.capture('holidays_calendar', { event: 'open_calendar_from_notification' });
      notifications.forEach(({ rangeId }) => dismissNotification(rangeId + timePrefix));
    },
  });
};

export const useHolidaysNotifications = () => {
  const { data, isLoading } = useHolidaysCalendar();
  const { dismissNotification, isNotificationDismissed } = useHolidaysNotificationStore();

  useEffect(() => {
    if (!data || isLoading) return;

    const today = dayjs();

    const nextDay = today.add(1, 'day');

    const nextDayNotifications = data.holidaysList.filter(
      ({ date, rangeId }) =>
        (nextDay.isSame(date, 'day') || today.isSame(date, 'day')) &&
        !isNotificationDismissed(rangeId + DAY_ID_PREFIX),
    );

    if (nextDayNotifications.length) {
      posthog?.capture('holidays_calendar', { event: 'notification_shown', type: 'day' });
      return showNotification(
        'We are going on holidays',
        nextDayNotifications,
        DAY_ID_PREFIX,
        dismissNotification,
      );
    }

    const nextWeekStart = today.add(1, 'week').startOf('week');
    const nextWeekEnd = today.add(1, 'week').endOf('week');

    const nextWeekNotifications = data.holidaysList.filter(
      ({ date, rangeId }) =>
        dayjs(date).isAfter(nextWeekStart) &&
        dayjs(date).isBefore(nextWeekEnd) &&
        !isNotificationDismissed(rangeId + WEEK_ID_PREFIX),
    );

    if (nextWeekNotifications.length) {
      posthog?.capture('holidays_calendar', { event: 'notification_shown', type: 'week' });
      return showNotification(
        'We will be on holiday next week',
        nextWeekNotifications,
        WEEK_ID_PREFIX,
        dismissNotification,
      );
    }

    const nextMonth = today.add(1, 'month').month();

    const nextMonthNotifications = data.holidaysList.filter(
      ({ date, rangeId }) =>
        dayjs(date).month() === nextMonth && !isNotificationDismissed(rangeId + MONTH_ID_PREFIX),
    );

    if (nextMonthNotifications.length) {
      posthog?.capture('holidays_calendar', { event: 'notification_shown', type: 'month' });
      return showNotification(
        'We will be on holiday next month',
        nextMonthNotifications,
        MONTH_ID_PREFIX,
        dismissNotification,
      );
    }
  }, [data, dismissNotification, isLoading, isNotificationDismissed]);
};
