import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

import { cx } from '~/common/utils';
import { Button } from '../Interactives';
import css from './styles.module.scss';

type Props = {
  icon: IconProp;
  /**
   * @tip If you need non standard size, don't set size and use className instead
   */
  size?: 's' | 'm' | 'l';
  iconProps?: Omit<FontAwesomeIconProps, 'icon'>;
  disabled?: boolean;
  className?: string;
};

const getProps = <T,>({ className, icon, iconProps, size, ...props }: Props & T) => ({
  ...props,
  className: cx(
    'flex flex-none items-center justify-center',
    size && css[`iconSize-${size}`],
    className,
    {
      [css.disabled]: props.disabled,
    },
  ),
  children: <FontAwesomeIcon {...iconProps} icon={icon} />,
});

type IconBoxProps = Props & ComponentPropsWithoutRef<'div'>;

//TODO create an icon size map from figma
export const IconBox = forwardRef<HTMLDivElement, IconBoxProps>((props, ref) => (
  <div ref={ref} {...getProps(props)} />
));

type IconButtonProps = Props & ComponentPropsWithoutRef<'button'>;

// TODO get rid of this component
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const { className, ...buttonProps } = getProps(props);
  return <Button.Base {...buttonProps} ref={ref} className={cx(className, 'focus-visible:ring')} />;
});
